define("ember-bootstrap/helpers/bs-type-class", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.typeClassHelper = typeClassHelper;

  function typeClassHelper([prefix, type], {
    default: defaultValue,
    outline = false
  }) {
    var _type;

    type = (_type = type) !== null && _type !== void 0 ? _type : defaultValue;

    if (outline) {
      return "".concat(prefix, "-outline-").concat(type);
    }

    return "".concat(prefix, "-").concat(type);
  }

  var _default = (0, _helper.helper)(typeClassHelper);

  _exports.default = _default;
});