define("admin-lte-ember/components/alte/content-header", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class="content-header">
    <div class="{{this.container}}">
      <div class="row mb-2">
        <div class="col-sm-6">
          {{#if @title}}
          <h1 class="m-0">{{@title}}</h1>
          {{else if (has-block "title")}}
          <h1 class="m-0">{{yield to="title"}}</h1>
          {{/if}}
        </div>
        {{#if (has-block "rightContent")}}
        <div class="col-sm-6">{{yield to="rightContent"}}</div>
        {{/if}}
      </div>
    </div>
  </section>
  
  */
  {
    "id": "oVcMit6V",
    "block": "[[[10,\"section\"],[14,0,\"content-header\"],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[30,0,[\"container\"]]]]],[12],[1,\"\\n    \"],[10,0],[14,0,\"row mb-2\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col-sm-6\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"        \"],[10,\"h1\"],[14,0,\"m-0\"],[12],[1,[30,1]],[13],[1,\"\\n\"]],[]],[[[41,[48,[30,2]],[[[1,\"        \"],[10,\"h1\"],[14,0,\"m-0\"],[12],[18,2,null],[13],[1,\"\\n        \"]],[]],null]],[]]],[1,\"      \"],[13],[1,\"\\n\"],[41,[48,[30,3]],[[[1,\"      \"],[10,0],[14,0,\"col-sm-6\"],[12],[18,3,null],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@title\",\"&title\",\"&rightContent\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "admin-lte-ember/components/alte/content-header.hbs",
    "isStrictMode": false
  });

  class ContentHeaderComponent extends _component2.default {
    get container() {
      var _this$args$container;

      return (_this$args$container = this.args.container) !== null && _this$args$container !== void 0 ? _this$args$container : 'container-fluid';
    }

  }

  _exports.default = ContentHeaderComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ContentHeaderComponent);
});