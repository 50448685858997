define("ember-intl/-private/utils/missing-message", ["exports", "@ember/utils", "@ember/debug"], function (_exports, _utils, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = missingMessage;

  /**
   * @private
   * @hide
   */
  function missingMessage(key, locales, _options) {
    if ((0, _utils.isEmpty)(locales)) {
      (false && (0, _debug.warn)("[ember-intl] no locale has been set!  See: https://ember-intl.github.io/ember-intl/docs/quickstart#4-configure-ember-intl", false, {
        id: 'ember-intl-no-locale-set'
      }));
      return "No locale defined.  Unable to resolve translation: \"".concat(key, "\"");
    }

    const localeNames = locales.join(', ');
    (false && (0, _debug.warn)("[ember-intl] translation: \"".concat(key, "\" on locale: \"").concat(localeNames, "\" was not found."), false, {
      id: 'ember-intl-missing-translation'
    }));
    return "Missing translation \"".concat(key, "\" for locale \"").concat(localeNames, "\"");
  }
});