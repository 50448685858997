define("ember-popper/components/ember-popper-targeting-parent", ["exports", "ember-popper/components/ember-popper-base", "ember-popper/templates/components/ember-popper-targeting-parent", "@ember/object/internals"], function (_exports, _emberPopperBase, _emberPopperTargetingParent, _internals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberPopperBase.default.extend({
    layout: _emberPopperTargetingParent.default,

    // ================== LIFECYCLE HOOKS ==================
    init() {
      this.id = this.id || "".concat((0, _internals.guidFor)(this), "-popper");
      this._parentFinder = self.document ? self.document.createTextNode('') : '';

      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      this._initialParentNode = this._parentFinder.parentNode;
    },

    /**
     * Used to get the popper target whenever updating the Popper
     */
    _getPopperTarget() {
      return this._initialParentNode;
    }

  });

  _exports.default = _default;
});