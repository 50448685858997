define("ember-concurrency/-private/external/task-instance/base", ["exports", "ember-concurrency/-private/external/task-instance/initial-state", "ember-concurrency/-private/external/yieldables", "ember-concurrency/-private/external/task-instance/cancelation"], function (_exports, _initialState, _yieldables, _cancelation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.BaseTaskInstance = void 0;
  const EXPLICIT_CANCEL_REASON = '.cancel() was explicitly called';

  class BaseTaskInstance {
    constructor({
      task,
      args,
      executor,
      performType,
      hasEnabledEvents
    }) {
      this.task = task;
      this.args = args;
      this.performType = performType;
      this.executor = executor;
      this.executor.taskInstance = this;
      this.hasEnabledEvents = hasEnabledEvents;
    }

    setState() {}

    onStarted() {}

    onSuccess() {}

    onError() {}

    onCancel() {}

    formatCancelReason() {}

    selfCancelLoopWarning() {}

    onFinalize(callback) {
      this.executor.onFinalize(callback);
    }

    proceed(index, yieldResumeType, value) {
      this.executor.proceedChecked(index, yieldResumeType, value);
    }

    [_yieldables.yieldableSymbol](parentTaskInstance, resumeIndex) {
      return this.executor.onYielded(parentTaskInstance, resumeIndex);
    }

    cancel(cancelReason = EXPLICIT_CANCEL_REASON) {
      this.executor.cancel(new _cancelation.CancelRequest(_cancelation.CANCEL_KIND_EXPLICIT, cancelReason));
    }

    then(...args) {
      return this.executor.promise().then(...args);
    }

    catch(...args) {
      return this.executor.promise().catch(...args);
    }

    finally(...args) {
      return this.executor.promise().finally(...args);
    }

    toString() {
      return "".concat(this.task, " TaskInstance");
    }

    start() {
      this.executor.start();
      return this;
    }

  }

  _exports.BaseTaskInstance = BaseTaskInstance;
  Object.assign(BaseTaskInstance.prototype, _initialState.INITIAL_STATE);
  Object.assign(BaseTaskInstance.prototype, {
    state: 'waiting',
    isDropped: false,
    isRunning: true
  });
});