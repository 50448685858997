define("@embroider/util/services/ensure-registered", ["exports", "@ember/service", "@ember/application"], function (_exports, _service, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class EnsureRegisteredService extends _service.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "classNonces", new WeakMap());

      _defineProperty(this, "nonceCounter", 0);
    }

    register(klass, owner = (0, _application.getOwner)(this)) {
      let nonce = this.classNonces.get(klass);

      if (nonce == null) {
        nonce = "-ensure".concat(this.nonceCounter++);
        this.classNonces.set(klass, nonce);
        owner.register("component:".concat(nonce), klass);
      }

      return nonce;
    }

  }

  _exports.default = EnsureRegisteredService;
});