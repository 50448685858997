define("ember-bootstrap/utils/cp/listen-to", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  /**
   * CP macro that listens to dependent (external) property, but allows overriding it locally without violating DDAU
   * By using a simple setter it will still trigger on changes of the dependent property even when being set before.
   *
   * Kudos to @fsmanuel for coming up with this solution.
   *
   * @method
   * @return {boolean}
   * @param {string} dependentKey
   * @param {*} defaultValue
   * @private
   */
  function _default(dependentKey, defaultValue = null) {
    return (0, _object.computed)(dependentKey, {
      get() {
        var _this$dependentKey;

        return (_this$dependentKey = this[dependentKey]) !== null && _this$dependentKey !== void 0 ? _this$dependentKey : defaultValue;
      },

      set(key, value) {
        // eslint-disable-line no-unused-vars
        return value;
      }

    });
  }
});