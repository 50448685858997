define("ember-bootstrap/components/bs-nav", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component", "@ember/object", "ember-bootstrap/utils/default-decorator", "ember-bootstrap/utils/deprecate-subclassing"], function (_exports, _component, _templateFactory, _component2, _object, _defaultDecorator, _deprecateSubclassing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ul class="nav {{this.typeClass}} {{this.additionalClass}} {{if this.justified "nav-justified"}} {{if this.stacked (if (macroCondition (macroGetOwnConfig "isNotBS3")) "flex-column" "nav-stacked")}} {{if (macroCondition (macroGetOwnConfig "isNotBS3")) (if this.fill "nav-fill")}}" ...attributes>
    {{yield
      (hash
        item=(ensure-safe-component (bs-default @itemComponent (component "bs-nav/item")))
        link-to=(ensure-safe-component (bs-default @linkToComponent (component "bs-link-to" class=(if (macroCondition (macroGetOwnConfig "isNotBS3")) "nav-link"))))
        linkTo=(ensure-safe-component (bs-default @linkToComponent (component "bs-link-to" class=(if (macroCondition (macroGetOwnConfig "isNotBS3")) "nav-link"))))
        dropdown=(component (ensure-safe-component (bs-default @dropdownComponent (component "bs-dropdown"))) inNav=true htmlTag="li")
      )
    }}
  </ul>
  */
  {
    "id": "QKZ7QYR5",
    "block": "[[[11,\"ul\"],[16,0,[29,[\"nav \",[30,0,[\"typeClass\"]],\" \",[30,0,[\"additionalClass\"]],\" \",[52,[30,0,[\"justified\"]],\"nav-justified\"],\" \",[52,[30,0,[\"stacked\"]],\"flex-column\"],\" \",[52,[30,0,[\"fill\"]],\"nav-fill\"]]]],[17,1],[12],[1,\"\\n  \"],[18,5,[[28,[37,2],null,[[\"item\",\"link-to\",\"linkTo\",\"dropdown\"],[[28,[37,3],[[28,[37,4],[[30,2],[50,\"bs-nav/item\",0,null,null]],null]],null],[28,[37,3],[[28,[37,4],[[30,3],[50,\"bs-link-to\",0,null,[[\"class\"],[\"nav-link\"]]]],null]],null],[28,[37,3],[[28,[37,4],[[30,3],[50,\"bs-link-to\",0,null,[[\"class\"],[\"nav-link\"]]]],null]],null],[50,[28,[37,3],[[28,[37,4],[[30,4],[50,\"bs-dropdown\",0,null,null]],null]],null],0,null,[[\"inNav\",\"htmlTag\"],[true,\"li\"]]]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@itemComponent\",\"@linkToComponent\",\"@dropdownComponent\",\"&default\"],false,[\"if\",\"yield\",\"hash\",\"ensure-safe-component\",\"bs-default\",\"component\"]]",
    "moduleName": "ember-bootstrap/components/bs-nav.hbs",
    "isStrictMode": false
  });

  let Nav = (
  /**
    Component to generate [bootstrap navs](http://getbootstrap.com/components/#nav)
  
    ### Usage
  
    Use in combination with the yielded components
  
    * [Components.NavItem](Components.NavItem.html)
    * [`nav.dropdown`](Components.Dropdown.html)
  
    ```hbs
    <BsNav @type="pills" as |nav|>
      <nav.item>
        <nav.linkTo @route="foo">
          Foo
        </nav.linkTo>
      </nav.item>
      <nav.item>
        <nav.linkTo @route="bar" @model={{this.model}}>
          Bar
        </nav.linkTo>
      </nav.item>
    </BsNav>
    ```
  
    > Note: the use of angle brackets `<nav.linkTo>` as shown above is only supported for Ember >= 3.10, as it relies on
    > Ember's native implementation of the [`LinkComponent`](https://api.emberjs.com/ember/3.12/classes/Ember.Templates.helpers/methods/link-to?anchor=link-to).
    > For older Ember versions please use the legacy syntax with positional arguments: `{{#nav.link-to "bar" this.model}}Bar{{/nav.link-to}}`
  
    ### Nav styles
  
    The component supports the default bootstrap nav styling options "pills" and "tabs" through the `type`
    property, as well as the `justified`, `fill` and `stacked` properties.
  
    ### Active items
  
    Bootstrap 3 expects to have the `active` class on the `<li>` element that should be the active (highlighted)
    navigation item. To achieve that use the `@route` and optionally `@model` (or `@models`) and `@query` properties
    of the yielded `nav.linkTo` component just as you would for Ember's `<LinkTo>` component to create a link with proper
    `active` class support.
  
    ### Dropdowns
  
    Use the `nav.dropdown` contextual version of the [Components.Dropdown](Components.Dropdown.html) component
    with a `tagName` of "li" to integrate a dropdown into your nav:
  
    ```hbs
    <BsNav @type="pills" as |nav|>
      <nav.item>
        <nav.linkTo @route="index">
          Home
        </nav.linkTo>
      </nav.item>
      <nav.dropdown as |dd|>
        <dd.toggle>Dropdown <span class="caret"></span></dd.toggle>
        <dd.menu as |ddm|>
          <ddm.item><ddm.linkTo @route="foo">Foo</ddm.linkTo></ddm.item>
          <ddm.item><ddm.linkTo @route="bar">Bar</ddm.linkTo></ddm.item>
        </dd.menu>
      </nav.dropdown>
    </BsNav>
    ```
  
    ### Bootstrap 3/4 Notes
  
    Use [`nav.linkTo`](Components.NavLinkTo.html) for in-app links to ensure proper styling regardless of
    Bootstrap version. Explicit use of `<a>` tags in Bootstrap 4 must apply the `nav-link` class and manage
    the `active` state explicitly.
  
    The `fill` styling is only available with Bootstrap 4
  
    *Note that only invoking the component in a template as shown above is considered part of its public API. Extending from it (subclassing) is generally not supported, and may break at any time.*
  
    @class Nav
    @namespace Components
    @extends Ember.Component
    @public
  
   */
  _dec = (0, _component2.tagName)(''), _dec2 = (0, _object.computed)('type'), _dec(_class = (0, _deprecateSubclassing.default)(_class = (_class2 = class Nav extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "type", _descriptor, this);

      _initializerDefineProperty(this, "justified", _descriptor2, this);

      _initializerDefineProperty(this, "stacked", _descriptor3, this);

      _initializerDefineProperty(this, "fill", _descriptor4, this);
    }

    get typeClass() {
      let type = this.type;
      return type ? "nav-".concat(type) : undefined;
    }
    /**
     * Special type of nav, either "pills" or "tabs"
     *
     * @property type
     * @type String
     * @default null
     * @public
     */

    /**
     * @property itemComponent
     * @type {String}
     * @private
     */

    /**
     * @property linkToComponent
     * @type {String}
     * @private
     */

    /**
     * @property dropdownComponent
     * @type {String}
     * @private
     */


  }, (_applyDecoratedDescriptor(_class2.prototype, "typeClass", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "typeClass"), _class2.prototype), _descriptor = _applyDecoratedDescriptor(_class2.prototype, "type", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "justified", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "stacked", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "fill", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class2)) || _class) || _class);
  _exports.default = Nav;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Nav);
});