define("ember-element-helper/helpers/-element", ["exports", "@ember/component/helper", "@ember/debug", "@ember/component"], function (_exports, _helper, _debug, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function UNINITIALIZED() {}

  const DynamicElement = _component.default.extend();

  const DynamicElementAlt = _component.default.extend();

  var _default = _helper.default.extend({
    init() {
      this._super(...arguments);

      this.tagName = UNINITIALIZED;
      this.componentClass = null;
    },

    compute(params, hash) {
      (false && !(params.length === 1) && (0, _debug.assert)('The `element` helper takes a single positional argument', params.length === 1));
      (false && !(Object.keys(hash).length === 0) && (0, _debug.assert)('The `element` helper does not take any named arguments', Object.keys(hash).length === 0));
      let tagName = params[0];

      if (tagName !== this.tagName) {
        this.tagName = tagName;

        if (typeof tagName === 'string') {
          // return a different component name to force a teardown
          if (this.componentClass === DynamicElement) {
            this.componentClass = DynamicElementAlt;
          } else {
            this.componentClass = DynamicElement;
          }
        } else {
          this.componentClass = null;
          (0, _debug.runInDebug)(() => {
            let message = 'The argument passed to the `element` helper must be a string';

            try {
              message += " (you passed `".concat(tagName, "`)");
            } catch (e) {// ignore
            }

            (false && !(tagName === undefined || tagName === null) && (0, _debug.assert)(message, tagName === undefined || tagName === null));
          });
        }
      }

      return this.componentClass;
    }

  });

  _exports.default = _default;
});